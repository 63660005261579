import BaseService from './BaseService';

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        // Extracting the error message
                        reject({
                            message:
                                error.response.data.message ||
                                'An error occurred',
                            status: error.response.status,
                            data: error.response.data,
                        });
                    } else {
                        // Generic error handling
                        reject({
                            message: error.message || 'Network error',
                        });
                    }
                });
        });
    },
};

export default ApiService;
