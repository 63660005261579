import React from 'react';
import classNames from 'classnames';
import { HiChevronLeft } from 'react-icons/hi';
import { FiArrowLeft } from 'react-icons/fi';

const Prev = (props) => {
    const { currentPage, pagerClass, onPrev } = props;

    const disabled = currentPage <= 1;

    const onPrevClick = (e) => {
        if (disabled) {
            return;
        }
        onPrev(e);
    };

    const pagerPrevClass = classNames(
        pagerClass.default,
        'pagination-pager-prev',
        disabled ? pagerClass.disabled : pagerClass.inactive
    );

    return (
        <span
            className={
                `flex cursor-pointer items-center gap-1 bg-white border border-[#D0D5DD] px-2 py-1 rounded-lg shadow-sm` ||
                pagerPrevClass
            }
            style={{
                color: disabled ? '' : '#000',
                fontWeight: '500',
                fontSize: '12px',
            }}
            onClick={onPrevClick}
        >
            <FiArrowLeft />
            Previous
        </span>
    );
};

export default Prev;
