// const appConfig = {
//     // production
//     // apiPrefix: 'https://api.rebatus.com',

//     //staging
//    apiPrefix: 'https://staging-api.rebatus.com',

//     //local
//     //  apiPrefix: 'http://localhost:5000',

//     authenticatedEntryPath: '/home',
//     LandingEntry: '/lannding',
//     unAuthenticatedEntryPath: '/login',
//     tourPath: '/',
//     locale: 'en',
//     enableMock: false,
// };

// export default appConfig;

import localAppConfig from './config.local';
import productionConfig from './config.production';
import stagingConfig from './config.staging';

const getConfigFile = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'production':
            return productionConfig;
        case 'staging':
            return stagingConfig;
        default:
            return localAppConfig;
    }
};

const appConfig = getConfigFile();
export default appConfig;
