const localAppConfig = {
    apiPrefix: 'http://localhost:5000',

    authenticatedEntryPath: '/home',
    LandingEntry: '/lannding',
    unAuthenticatedEntryPath: '/login',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
};

export default localAppConfig;
