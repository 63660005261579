const productionAppConfig = {
    apiPrefix: 'https://api.rebatus.com',

    authenticatedEntryPath: '/home',
    LandingEntry: '/lannding',
    unAuthenticatedEntryPath: '/login',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
};

export default productionAppConfig;
