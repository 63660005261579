import React from 'react';
import classNames from 'classnames';
import { HiChevronRight } from 'react-icons/hi';
import { BiArrowToRight } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';

const Next = (props) => {
    const { currentPage, pageCount, pagerClass, onNext } = props;

    const disabled = currentPage === pageCount || pageCount === 0;

    const onNextClick = (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        onNext(e);
    };

    const pagerNextClass = classNames(
        pagerClass.default,
        'pagination-pager-next',
        disabled ? pagerClass.disabled : pagerClass.inactive
    );

    return (
        <span
            className={
                'flex cursor-pointer items-center gap-1 bg-white border border-[#D0D5DD] px-2 py-1 rounded-lg shadow-sm' ||
                pagerNextClass
            }
            onClick={onNextClick}
            style={{
                color: disabled ? '' : '#000',
                fontSize: '12px',
                fontWeight: '500',
            }}
        >
            Next
            <FiArrowRight />
        </span>
    );
};

export default Next;
